import React, { useRef, useContext } from 'react';
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion';

import styled from 'src/components/styled';

const Container = styled(motion.main)`
  /* overflow-x: hidden; */
`;

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const variants = {
  initial: { opacity: 1 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
};

// let lastKnownScrollPosition = 0;
// let lastHandledScrollPos = 0;

export default function RootLayout({ children, CursorContext }: { children: React.ReactNode }) {
  // const containerRef = useRef(null);
  const { x, y, scale, animation } = useContext(CursorContext);

  const handleMouseMove = (event) => {
    x.set(event.clientX - 18 / 2);
    y.set(event.clientY - 18 / 2);
    scale.set(1);
    // animation.start({ backgroundColor: 'rgba(77, 77, 77, 0.92)' });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Container
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        onMouseMove={handleMouseMove}
        // ref={containerRef}
      >
        {children}
      </Container>
    </AnimatePresence>
  );
}
