import React, { useState, useContext, useEffect } from 'react';
import { motion, useMotionValue, useSpring, useAnimation, useInvertedScale } from 'framer-motion';

import styled from 'src/components/styled';

const boxSize = 30;
const containerSize = 200;

const BorderedRectangle = ({ scale }) => {
  const inverted = useInvertedScale();

  return (
    <motion.div
      style={{
        ...inverted,
        position: 'absolute',
        scaleX: scale,
        scaleY: 7,
        opacity: scale,
        width: 100,
        height: 15,
        border: '1px solid #fff',
      }}
    />
  );
};

const MotionCursor = styled(motion.span)`
  position: fixed;
  z-index: 10000;
  height: 18px;
  width: 18px;
  pointer-events: none;
  background-color: rgba(200, 200, 200, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  @media (hover: none) {
    display: none;
  }
`;

export default function Cursor({ CursorContext, location }) {
  const cursor = useContext(CursorContext);
  cursor.x = useMotionValue(-boxSize);
  cursor.y = useMotionValue(-boxSize);
  cursor.scale = useSpring(1, { stiffness: 300, damping: 20 });
  cursor.scaleX = useSpring(1, { stiffness: 300, damping: 20 });
  cursor.scaleY = useSpring(1, { stiffness: 300, damping: 20 });
  cursor.borderScale = useSpring(0, { stiffness: 300, damping: 30 });
  cursor.borderRadius = useSpring(9, { stiffness: 300, damping: 20 });
  cursor.textOpacity = useMotionValue(0);
  // cursor.background = useMotionValue('#f00');
  cursor.animation = useAnimation();
  const [cursorText, setCursorText] = useState('CLICK');
  cursor.setCursorText = setCursorText;

  cursor.x.onChange(() => {
    const velocity = Math.abs(cursor.x.getVelocity());
    if (velocity > 1) {
      cursor.scaleY.set(1 / (Math.log(velocity) / 30 + 1));
    } else {
      cursor.scaleY.set(1);
    }
  });
  cursor.y.onChange(() => {
    const velocity = Math.abs(cursor.y.getVelocity());
    if (velocity > 1) {
      cursor.scaleX.set(1 / (Math.log(velocity) / 30 + 1));
    } else {
      cursor.scaleX.set(1);
    }
  });

  useEffect(() => {
    cursor.animation.start({ opacity: 1 }, { duration: 0.1 });
  }, [cursor.animation, location.pathname]);

  return (
    <>
      <MotionCursor
        style={{
          borderRadius: cursor.borderRadius,
          scale: cursor.scale,
          scaleX: cursor.scaleX,
          scaleY: cursor.scaleY,
          x: cursor.x,
          y: cursor.y,
        }}
        animate={cursor.animation}
        key="cursor"
        id="cursor"
      />
    </>
  );
}
