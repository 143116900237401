import React from 'react';
import { css, Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { SanitizeCSS } from 'emotion-sanitize';

import styled from 'src/components/styled';
import theme from 'src/theme';
import AnimationLayout from './AnimationLayout';
import Cursor from 'src/components/Cursor';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]');
}

const cursor = {};

export const CursorContext = React.createContext(cursor);

export default function RootLayout({ children, location }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <SanitizeCSS sanitize forms typography />
      <Global
        styles={css`
          body {
            overflow-x: hidden;
          }
          html * {
            cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjbQg61aAAAADUlEQVQYV2P4//8/IwAI/QL/+TZZdwAAAABJRU5ErkJggg=='),
              none !important;
          }
          button:focus {
            outline: 0;
          }
        `}
      />
      <CursorContext.Provider value={cursor}>
        <Cursor CursorContext={CursorContext} location={location} />
        <AnimationLayout CursorContext={CursorContext}>{children}</AnimationLayout>
      </CursorContext.Provider>
    </ThemeProvider>
  );
}
