import styled, { CreateStyled } from '@emotion/styled';
import facepaint from 'facepaint';

import theme from 'src/theme';

export const mq = facepaint(theme.breakpoints.map((bp) => `@media(min-width: ${bp})`));

type Theme = typeof theme;

export default styled as CreateStyled<Theme>;
