export default {
  colors: {
    text: '#1F1F1F',
    mutedText: '#777777',
    background: '#f1f1f1',
    lightBackground: '#fff',
    lightText: '#fff',
    lightTextMuted: '#F0F0F0',
    primary: '#fff',
    secondary: '#73D0FF',
    highlight: 'hsl(230, 20%, 40%)',
    purple: 'hsl(260, 100%, 80%)',
    muted: '#1b2731',
    gray: 'hsl(190, 50%, 60%)',
  },
  breakpoints: ['480px', '768px', '992px', '1200px'],
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256],
  fonts: {
    body: '"Apercu Pro", sans-serif',
    heading: '"Apercu Pro", sans-serif',
    display: '"Rubik", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 128, 164, 256],
  fontWeights: {
    body: 400,
    heading: 500,
    display: 700,
  },
  lineHeights: {
    body: 1.5,
    display: 1.33,
    heading: 1.33,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    display: {
      fontFamily: 'display',
      fontWeight: 'display',
      lineHeight: 'display',
      fontSize: [6, 6, 6, 10],
    },
    subtitle: {
      fontWeight: 500,
      letterSpacing: 1.4,
    },
  },
  styles: {
    Container: {
      p: 3,
      maxWidth: 1024,
    },
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 2,
    },
    a: {
      color: 'primary',
      '&:hover': {
        color: 'secondary',
      },
    },
    pre: {
      variant: 'prism',
      fontFamily: 'monospace',
      fontSize: 1,
      p: 3,
      color: 'text',
      bg: 'muted',
      overflow: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      color: 'secondary',
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: 'monospace',
      color: 'secondary',
      bg: 'muted',
    },
    table: {
      width: '100%',
      my: 4,
      borderCollapse: 'separate',
      borderSpacing: 0,
      'th,td': {
        textAlign: 'left',
        py: '4px',
        pr: '4px',
        pl: 0,
        borderColor: 'muted',
        borderBottomStyle: 'solid',
      },
    },
    th: {
      verticalAlign: 'bottom',
      borderBottomWidth: '2px',
    },
    td: {
      verticalAlign: 'top',
      borderBottomWidth: '1px',
    },
    hr: {
      border: 0,
      borderBottom: '1px solid',
      borderColor: 'muted',
    },
    img: {
      maxWidth: '100%',
    },
  },
  links: {
    nav: {
      px: 2,
      py: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
  },
  images: {
    avatar: {
      width: 40,
      height: 40,
      borderRadius: 16,
    },
  },
  buttons: {
    primary: {
      border: 'none',
      color: 'muted',
      backgroundColor: 'primary',
      padding: '20px 48px',
      fontSize: 1,
      letterSpacing: '0.1em',
      fontFamily: 'heading',
      fontWeight: 'display',
      cursor: 'pointer',
      '&:hover': {
        color: 'secondary',
      },
    },
    outlined: {
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: 'primary',
      fontFamily: 'body',
      fontSize: 1,
      letterSpacing: 0.6,
      fontWeight: 500,
      borderRadius: 0,
      textTransform: 'uppercase',
      px: 4,
      py: 2,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  badges: {
    simple: {
      backgroundColor: 'transparent',
      fontSize: 2,
      color: 'mutedText',
      letterSpacing: 1.2,
      textTransform: 'uppercase',
    },
  },
  mainContainer: {
    px: [4, 7, 8, 0],
    maxWidth: ['100%', '100%', '100%', 960, 1140],
    mx: 'auto',
  },
};
